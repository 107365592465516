@import "../../../../styles/_variables.css";

.embedVideo {
  width: 100%;
  border-radius: 3px 3px 3px 3px;
  border: 1px solid #e8e8e8;
  box-shadow: 0 2px 4px rgba(0,0,0,.05);
}

.competitor-video {
  padding: 16px 6px 16px 0;
  @media(--breakpoint-small-device) {
    padding: 32px 16px 32px 0;
  }
  @media(--breakpoint-medium-device) {
    padding: 48px 23px 48px 0;
  }
}

.speedKit-video {
  padding: 16px 0 16px 6px;
  @media(--breakpoint-small-device) {
    padding: 32px 0 32px 16px;
  }
  @media(--breakpoint-medium-device) {
    padding: 48px 0 48px 23px;
  }
}

.video__wrapper {
  position: relative;
  &:not(.auto):before {
    content: "";
    display: block;
    position: relative;
    padding-top: 56.5%;
    top: 1px;
    .mobile & {
      padding-top: 68%;
    }
  }
  .video__wrapper-inner {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 0 4px 32px 0 rgba(51, 53, 55, 0.44);
    .relative {
      width: 101%;
      height: 133%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: -9.35%;
      background-color: black;
      video {
        width: 100%;
        /* min-height: 120.5%; */
        /* background: #f8f8f8; */
        border: none;
        z-index: 1;
        /* min-height: 100%; */
        flex-shrink: 0;
        flex-grow: 1;
      }
      .mobile & {
        height: 107%;
        margin-top: -4.25%;
        video {
          margin-top: initial;
          width: 100%;
          min-height: 105%;
          position: relative;
          margin-left: auto;
          display: block;
          margin-right: auto;
        }
      }
    }
  }
  .video__wrapper-play {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -22.5px;
    margin-top: -22.5px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    backdrop-filter: blur(2px);
    box-shadow: 0 0 16px 0 rgba(155, 155, 155, 0.4);
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 2;
    &:hover {
      color: white;
    }
    @media(--breakpoint-small-device) {
      width: 100px;
      height: 100px;
      margin-left: -48.5px;
      margin-top: -26.5px;
    }
  }
  .video__wrapper-play-inner {
    position: relative;
    font-size: 15px;
    cursor: pointer;
    width: 39px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    border-radius: 39px;
    box-shadow: 0 2px 8px 0 rgba(51, 53, 55, 0.56);
    background-image: linear-gradient(to bottom, #ffffff, #efeff0);
    z-index: 2;
    &:hover {
      opacity: 0.7;
    }
    &.error {
      cursor: auto;
      padding-left: 0;
      &:hover {
         opacity: 1.0;
      }
    }
    @media(--breakpoint-small-device) {
      width: 78px;
      height: 78px;
      font-size: 30px;
    }
    .play {
      width: 21px;
    }
    .locked {
      width: 16px;
      @media(--breakpoint-small-device) {
        width: 26px;
      }
    }
  }
}
