@import "../../../../styles/_variables.css";

a {
  color: inherit;
}

.slick-slider {
  display: grid !important;
  img {
    display: inline !important;
  }
}

.slick-list {
  text-align: center;
}

.slick-dots li {
  margin: 0 10px;
}

.slick-dots li button:before {
  font-size: 30px !important;
  color: #ffffff !important;
}

.slick-dots li.slick-active button:before {
  opacity: 1 !important;
}

.item-img {
  height: 25px;
  @media (--breakpoint-small-device) {
    height: 30px;
  }
}

.item-header {
  font-size: 13px;
  margin: 5px 0;
  color: rgba(255, 255, 255, 0.64);
}

.item-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 5px;
}

.customer {
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 25px;
  width: 100%;
  @media (--breakpoint-small-device) {
    background-size: auto 30px;
  }
  &.carhartt {
    background-image: url("../../../../assets/customers/carhartt-white.svg");
  }
  &.hsv {
    background-image: url("../../../../assets/customers/hsv-white.svg");
  }
  &.interflora {
    background-image: url("../../../../assets/customers/interflora-white.svg");
  }
  &.bmw {
    background-image: url("../../../../assets/customers/bmw-white.svg");
  }
}
