.toast {
  align-items: flex-start;
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  padding: 12px;
  background: color(#e74c3c a(95%));
  font-size: 14px;
  font-weight: 400;
}

.toast:not(:last-child) {
  margin: 0 0 12px;
}

.toast__content {
  flex: 1 1 auto;
  margin: 0 12px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.toast__dismiss {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: block;
  flex: 0 0 auto;
  font: inherit;
  padding: 0;
}

.toasts {
  top: 16px;
  position: fixed;
  right: 16px;
  width: 240px;
  z-index: 1234;
}
